

const notifications = () => {
   
    return (
        <>
        <h1>Coming Soon.....</h1>
        </>
    );
}

export default notifications